<template>
  <div v-loading="pageloading">
    <head-card>
      <div class="main">
        <div class="main-step">
          <div class="stepfont" :class="{bluefont:step==1}">选择版本</div>
          <i class="el-icon-arrow-right"></i>
          <div class="stepfont" :class="{bluefont:step==2}">选择套餐</div>
          <i class="el-icon-arrow-right"></i>
          <div class="stepfont" :class="{bluefont:step==3}">支付结账</div>
          <i class="el-icon-arrow-right"></i>
          <div class="stepfont" :class="{bluefont:step==4}">订购结果</div>
        </div>
        <div style="width:100%">
          <div class="main-content" v-if="step==1">
            <div class="versionDemo" v-for="(v,i) in version.PcMallVersionInfoList" :key="i">
              <div class="demotop" v-if="v.VersionLevel==1">
                <div class="demotop-content">
                  <div class="toph1">基础版</div>
                  <div class="toph2">
                    <span>适合初创型商家，能满足线上商城内容承载、营销等基本经营需求</span>
                  </div>
                </div>
                <img :src="imgUrl+'/image/versionOrderbalck.png'" class="imgbgc" alt="">
              </div>
              <div class="demotop" v-if="v.VersionLevel==2">
                <div class="demotop-content">
                  <div class="toph1">高级版</div>
                  <div class="toph2">
                    <span>适合成长型商家，实现多样化营销、分销裂变等经营需求</span>
                  </div>
                </div>
                <img :src="imgUrl+'/image/versionOrderyellow.png'" class="imgbgc" alt="">
              </div>
              <div class="demotop" v-if="v.VersionLevel==3">
                <div class="demotop-content">
                  <div class="toph1">旗舰版</div>
                  <div class="toph2">
                    <span>适合成熟型商家，可开展精细化人群运营、私域生态搭建等高阶经营需求</span>
                  </div>
                </div>
                <img :src="imgUrl+'/image/versionOrderred.png'" class="imgbgc" alt="">
              </div>
              <div class="democontent">
                <div class="price">
                  <span style="font-size:14px">￥</span>
                  <span style="font-size:40px;font-weight:bold;margin:0px 5px">{{v.MallVersionPriceSystemModelList[0].Price}}</span>
                  <span style="font-size:14px">元/{{conversion(v.MallVersionPriceSystemModelList[0],true)}}</span>
                </div>
                <el-button type="primary" class="choosebtn" style="" v-if="version.VersionLevel == 0" @click="choosever(v)">选择版本</el-button>
                <el-button type="primary" class="choosebtn" v-else-if="version.VersionLevel == v.VersionLevel" @click="choosever(v)">续费版本</el-button>
                <el-button type="primary" class="choosebtn" v-else-if="version.VersionLevel < v.VersionLevel" @click="choosever(v)">升级版本</el-button>
                <el-popover placement="bottom" trigger="hover" v-else>
                  <div style="max-width:300px">
                    商城当前已订购{{version.PcMallVersionInfoList.find(v=>v.VersionLevel==version.VersionLevel).VersionName}}，暂不支持选择订购更低版本
                  </div>
                  <div slot="reference" class="flexRow" style="justify-content:center">
                    <el-button class="choosebtn" :disabled="version.VersionLevel > v.VersionLevel">选择版本</el-button>
                  </div>
                </el-popover>
                <div class="democontains">
                  <div class="containshead">{{v.VersionName}}包含功能</div>
                  <div v-if="v.VersionLevel==1">
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        商品、订单、售后等基础经营版块
                      </span>
                    </div>
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        优惠券、满减送等丰富营销活动
                      </span>
                    </div>
                  </div>
                  <div v-if="v.VersionLevel==2">
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        拼团、抢购、助力领取等裂变营销
                      </span>
                    </div>
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        分销裂变功能，让客户助力商城推广
                      </span>
                    </div>
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        日历签到、积分商城等客户沉淀与维护
                      </span>
                    </div>
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        直播间、视频号、线下门店等多渠道销售场景
                      </span>
                    </div>
                  </div>
                  <div v-if="v.VersionLevel==3">
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        店员分销+客户分销，实现全民推广
                      </span>
                    </div>
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        企微SCRM，支持多项涨粉与裂变工具
                      </span>
                    </div>
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        客户标签自动化，分层管理客户
                      </span>
                    </div>
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        智能营销，开展精细化人群运营
                      </span>
                    </div>
                    <div class="containsfunction">
                      <div class="iconbox">
                        <i class="el-icon-check"></i>
                      </div>
                      <span>
                        微信客服，多场景、多渠道链接客户
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main-content flexoneCenter" v-if="step==2">
            <div class="chooseService">
              <div class="chooseService-title" style="font-size:20px">订购版本：{{MallVersionmsg.VersionName}}</div>
              <div class="chooseService-title" style="margin:20px 0px 10px 0px;">服务周期</div>
              <div class="chooseService-cycle">
                <div class="cycle-box" :class="{bluemask:MallVersionSubmitOrder.MallVersionPriceSystemId==x.Id}"
                   v-for="(x,y) in MallVersionmsg.MallVersionPriceSystemModelList" :key="y"
                   @click="choosesystem(x)">
                  <el-radio v-model="MallVersionSubmitOrder.MallVersionPriceSystemId" :label="x.Id">
                    <span style="color:#303133">{{conversion(x)}}</span>
                  </el-radio>
                  <div class="cycle-price">
                    <div class="topprice" style="text-align:right">￥{{x.Price.toFixed(2)}}</div>
                    <div class="bottomprice" style="text-align:right" v-if="spare(x)">节省￥{{spare(x)}}</div>
                  </div>
                </div>
              </div>
              <div class="chooseService-title" v-if="MallVersionSubmitOrder.MallVersionPriceSystemId
               &&(MallVersionmsg.IsSendOrderCount||MallVersionmsg.IsSendSmsCount)" style="margin:20px 0px">订购礼包</div>
              <div class="chooseService-giftbag" v-if="MallVersionSubmitOrder.MallVersionPriceSystemId">
                <div class="giftbag-box" v-if="MallVersionmsg.IsSendOrderCount">
                  <el-tag type="danger">礼包</el-tag>
                  <div class="giftbag-font">
                    免{{MallVersionmsg.SendOrderCount}}单订单额度
                  </div>
                </div>
                <div class="giftbag-box" v-if="MallVersionmsg.IsSendSmsCount">
                  <el-tag type="danger">礼包</el-tag>
                  <div class="giftbag-font">
                    赠送{{MallVersionmsg.SendSmsCount}}条短信额度
                  </div>
                </div>
              </div>
              <div class="flexRow" style="justify-content: space-between;margin-top:30px">
                <div class="chooseService-title">总计</div>
                <div class="pricefont">
                  <span v-if="MallVersionSubmitOrder.MallVersionPriceSystemId">
                  ￥{{MallVersionPriceSystem.Price}}
                  </span>
                </div>
              </div>
              <el-link type="primary"  href="https://jusnn6k8al.feishu.cn/docs/doccnJNJDVxdqsmmfq7NvtOfIgh"
               target="_blank" :underline="false">资费详情</el-link>

              <div class="flexRow" style="margin:20px 0px">
                 <el-checkbox v-model="isagree">
                   <span style="color:#606266">阅读并同意</span>
                 </el-checkbox>
                 <el-link type="primary"  v-for="(m,n) in Agreement" :key="n"
                  :href="m.href" target="_blank" :underline="false">《{{m.AgreementTitle}}》</el-link>
              </div>

              <el-button type="primary" style="width:100%;height:40px" @click="submitorder">提交订单</el-button>
            </div>
          </div>
          <div class="main-content flexoneCenter" v-if="step==3">
            <div class="pay">
              <div class="pay-graybox flexRow" style="justify-content: space-between;">
                <div class="flexRow">
                  <img :src="imgUrl+'/image/versionOrderTime.png'" style="width:34px;height:34px;margin-right:10px"/>
                  <div class="pay-tips">你的订单已提交成功</div>
                </div>
                <div>
                  <div class="pay-tips" style="margin-top:5px">金额:
                    <span style="color:#F54E37" v-if="MallVersionSubmitOrder.MallVersionPriceSystemId">
                      ￥{{paydata.OrderPrice}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="pay-graybox flexCol" style="margin-top:10px">
                <div class="flexRow" style="margin-top:30px">
                  <img :src="imgUrl+'/image/versionOrderzhifubao.png'" style="width:34px;height:34px;margin-right:10px"/>
                  <div class="pay-tips" style="color:#303133;font-size:24px">扫码付款</div>
                </div>
                <div class="pay-secfont" style="margin:10px 0px 38px 0px">打开手机支付宝，扫描下方二维码支付</div>
                <vue-qr :text="paydata.QrcodeUrl" :size="220"  ref="Qrcode"></vue-qr>
                <div class="flexRow">
                  <i class="el-icon-refresh" style="font-size:14px;color:#409eff;margin-right:10px"></i>
                  <el-button type="text" @click="getQrcodeUrl">重新获取二维码</el-button>
                </div>
              </div>
            </div>
          </div>
          <div  class="main-content flexoneCenter" v-if="step==4">
            <div class="pay" v-if="paySuccess">
              <div class="pay-graybox flexRow" style="justify-content:center">
                <i class="el-icon-success" style="font-size:48px;color:#14BE39;margin-right:10px"></i>
                <div>
                  <div class="pay-tips" style="font-size:20px">支付成功</div>
                  <div class="pay-secfont" style="font-size:14px">金额：
                    <span style="color:#F54E37" v-if="MallVersionSubmitOrder.MallVersionPriceSystemId">
                      ￥{{paydata.OrderPrice}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="pay-graybox" style="margin-top:15px">
                <div class="pay-tips" style="font-size:20px">订购详情</div>
                <div>
                  <div class="flexRow" style="justify-content:space-between;margin:15px 0px">
                    <div class="pay-suctips">订购版本</div>
                    <div class="pay-sucgraytips">
                      <span v-if="MallVersionSubmitOrder.MallVersionId">
                        {{MallVersionmsg.VersionName}}
                      </span>
                    </div>
                  </div>
                  <div class="flexRow" style="justify-content:space-between;margin:15px 0px">
                    <div class="pay-suctips">订购周期</div>
                    <div class="pay-sucgraytips">
                      <span v-if="MallVersionSubmitOrder.MallVersionPriceSystemId">
                        {{conversion(MallVersionPriceSystem)}}
                      </span>
                    </div>
                  </div>
                  <div class="flexRow" style="justify-content:space-between;margin:15px 0px" v-if="MallVersionSubmitOrder.MallVersionPriceSystemId
                    &&(MallVersionmsg.IsSendOrderCount||MallVersionmsg.IsSendSmsCount)">
                    <div class="pay-suctips">订购礼包</div>
                    <div>
                      <div class="pay-sucgraytips" v-if="MallVersionmsg.IsSendOrderCount">免{{MallVersionmsg.SendOrderCount}}单订单额度</div>
                      <div class="pay-sucgraytips" v-if="MallVersionmsg.IsSendSmsCount">赠送{{MallVersionmsg.SendSmsCount}}条短信额度</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pay" v-else>
              <div class="pay-graybox flexCol" style="align-items:center">
                <div class="flexRowStart">
                  <div class="flexRow">
                    <img :src="imgUrl+'/image/versionOrdergrayTime.png'" style="width:34px;height:34px;margin-right:10px"/>
                    <div>
                      <div class="pay-tips" style="font-size:16px">支付结果获取中...</div>
                      <div class="pay-tips">
                        金额：
                        <span style="color:#F54E37">￥{{paydata.OrderPrice}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flexRow">
                    <i class="el-icon-refresh" style="font-size:14px;color:#409eff;margin-right:10px"></i>
                    <el-button type="text" @click="getpaystate(true)">刷新支付结果</el-button>
                  </div>
                </div>
                <div class="pay-sucgraytips" style="margin:30px 0px">若遇到疑问，使用微信扫描下方二维码，添加专属客服，一对一为你答疑解惑</div>
                <img src="@/assets/img/20210719171848.jpg" style="width:226px" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="flexRow" style="margin-top:30px">
          <el-button @click="$router.go(-1)" v-if="step!=4">取消订购</el-button>
          <el-button @click="chooseversionagain" v-if="step==2">重新选择版本</el-button>
          <el-button v-if="step==3" @click="getpaystate">支付失败</el-button>
          <el-button type="primary" v-if="step==3" @click="getpaystate">我已支付成功</el-button>
          <el-button v-if="step==4" @click="$router.replace({path:'/home'})">返回首页</el-button>
        </div>
      </div>
      <el-card class="contact flexCol">
        <div class="contact-font1">添加专属客服</div>
        <div class="contact-font2">一对一为您答疑解惑</div>
        <img src="@/assets/img/20210719171848.jpg" style="width:150px" alt="">
      </el-card>
    </head-card>
  </div>
</template>

<script>
import headCard from "./components/headCard"
import config from "@/config"
import vueQr from 'vue-qr';
import {
  paymentRulemallVersionList,
  paymentRulemallVersionSubmitOrder,
  paymentRulemallVersionRefreshQrcode
} from "@/api/login"
import {
  paymentrulebuypaymentruleResult,
  mallsystemAgreementInfoInit
} from "@/api/sv1.0.0"
import {
  mapGetters
} from 'vuex'
export default {
  components:{
    headCard,
    vueQr
  },
  computed: {
    // ...mapGetters([
    //   'MallId',
    // ])
  },
  data () {
    return {
      imgUrl:config.IMG_BASE,
      pageloading:false,
      step:1,
      MallId:'',
      version:{},
      isagree:false,
      paydata:{
        OrderNo:'',
        QrcodeUrl:'',
        OrderPrice:'',
      },
      MallVersionSubmitOrder:{
        MallId:0,
        MallVersionId:'',
        MallVersionPriceSystemId:'',
      },
      MallVersionmsg:{},//版本信息 
      MallVersionPriceSystem:{},//周期价格信息
      paySuccess:false,

      Agreement:[],
    }
  },
  created () {

    this.MallId = localStorage.getItem('MallId')
    this.MallVersionSubmitOrder.MallId = localStorage.getItem('MallId')
    // console.log(this.MallId)
    this.getversionlist()
    this.getAgreement()
  },
  methods: {
    chooseversionagain(){
      this.step=1
      this.MallVersionPriceSystem={}
      this.MallVersionSubmitOrder.MallVersionPriceSystemId = ''
      this.getversionlist()
    },
    async getAgreement(){
      this.Agreement = []
      try{
        let res = await mallsystemAgreementInfoInit({AgreementType:7,MallId:this.MallId})
        if(res.IsSuccess){
          let data = res.Result
          data.href = 'https://jusnn6k8al.feishu.cn/docs/doccnDIbFfzao3Q6mRcrAE7xoPb'
          this.Agreement.push(data)
        }
        let res2 = await mallsystemAgreementInfoInit({AgreementType:8,MallId:this.MallId})
        if(res2.IsSuccess){
          let data2 = res2.Result
          data2.href = 'https://jusnn6k8al.feishu.cn/docs/doccntytJJA7H4g8IcuGxS8cYdf'
          this.Agreement.push(data2)
        }
        // console.log(this.Agreement)
      }finally{
        //
      }
    },  
    async getpaystate(isrefresh){
      //isrefresh  点击刷新
      try{
        this.pageloading = true
        let data = {
          PayOrderNo: this.paydata.OrderNo,
        }
        let res = await paymentrulebuypaymentruleResult(data)
        if (res.IsSuccess) {
          this.step = 4
          if(isrefresh){
            this.$message.success('支付结果刷新成功')
          }
          if (res.Result.IsPay) {
            this.paySuccess = true
            if(!isrefresh){
              this.$message.success('支付成功')
            }
          } else {
            this.paySuccess = false
          }
				}
      }finally{
        this.pageloading = false
      }
    },
    choosesystem(e){
      // console.log(e)
      this.MallVersionSubmitOrder.MallVersionPriceSystemId = e.Id
      this.MallVersionPriceSystem = e
    },
    async getQrcodeUrl(){
      try{
        this.pageloading = true
        let data = {
          MallId:this.MallId,
          OrderNo:this.paydata.OrderNo,
          
        }
        let res = await paymentRulemallVersionRefreshQrcode(data)
        if(res.IsSuccess){
          // console.log(res)
          this.paydata = res.Result
          this.$message.success('二维码获取成功')
          // console.log(this.paydata)
        }
      }finally{
        this.pageloading = false
      }
    },
    submitorder(){
      if(!this.MallVersionSubmitOrder.MallVersionPriceSystemId){
        this.$message.error('请选择服务周期')
        return 
      }else if(!this.isagree){
        let name = this.Agreement.map(v=>'《'+v.AgreementTitle+'》').join('')
        this.$message.error(`请阅读并同意${name}`)
        return
      }
      this.tocinfirm()
    },
    async tocinfirm(){
      try{
        this.pageloading = true
        let data = JSON.parse(JSON.stringify(this.MallVersionSubmitOrder))
        let res = await paymentRulemallVersionSubmitOrder(data)
        if(res.IsSuccess){
          // console.log(res.Result)
          this.paydata = res.Result
          this.step = 3
        }
      }finally{
        this.pageloading = false
      }
    },
    spare(e){
      // console.log(e)
      let minimum = this.MallVersionmsg.MallVersionPriceSystemModelList[0]
      let lowestprice = minimum.Price/minimum.ServiceCycleMonths
      let currentprice = lowestprice*e.ServiceCycleMonths - e.Price
      // console.log(currentprice,lowestprice)
      if(currentprice<=0){
        return ''
      }else{
        return currentprice.toFixed(2)
      }

    },
    async choosever(e){
      // console.log(e,22)
      this.step = 2
      await this.getversionlist()
      console.log(this.version)
      let newe = this.version.PcMallVersionInfoList.find(v=>v.Id==e.Id)
      this.MallVersionmsg = newe
      this.MallVersionSubmitOrder.MallVersionId = newe.Id
      // console.log(newe.MallVersionPriceSystemModelList.map(v=>v.ServiceCycleMonths))
    },
    conversion(item,noOne){
      //noOne选择版本时一年不需要展示一  选择周期服务需要展示一
      if(item.ServiceCycleMonths % 12 == 0){
        return item.ServiceCycleMonths / 12 ==1&&noOne? '年':item.ServiceCycleMonths / 12+'年'
      }else if(item.ServiceCycleMonths / 12 == 0.5){
        return '半年'
      }else{
        return item.ServiceCycleMonths + '个月'
      }
    },
    async getversionlist(){
      try{
        this.pageloading  =true
        let res = await paymentRulemallVersionList({
          MallId:this.MallId
        })
        if(res.IsSuccess){
          let version = res.Result
          version.PcMallVersionInfoList = version.PcMallVersionInfoList.map(v=>{
            v.MallVersionPriceSystemModelList.sort((a,b)=>{
              return a[this.step==1?'Price':'ServiceCycleMonths'] - b[this.step==1?'Price':'ServiceCycleMonths']
            })
            return v
          })
          // console.log(this.step,this.step==1?'Price':'ServiceCycleMonths')
          // version.VersionLevel=3
          this.version = version
        }
      }finally{
        this.pageloading = false
      }
    }
  }
}
</script>

<style lang = "less" scoped>
.flexRow{
  display: flex;
  align-items: center;
}
.flexCol{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flexRowStart{
  display:flex;
  justify-content: space-between;
  width:100%;
  align-items:flex-start
}
.flexoneCenter{
  display: flex;
  justify-content: center !important;
}
.pricefont{
  color: #303133;
  font-size: 16px;
  font-weight: bold;
}
.main{
  /* border:1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 1200px;
  margin: 0px auto 20px auto;
  .main-step{
    width: 800px;
    background: #F5F7FA;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    justify-content: space-around;
    .stepfont{
      font-size: 14px;
      color: #303133;
      line-height: 1.5;
      flex-shrink: 0;
      /* cursor: pointer; */
    }
    .bluefont{
      color: #409eff;
    }
  }
  .main-content{
    margin-top:20px;
    display: flex;
    justify-content: space-between;
    /* border:1px solid red; */
    width: 100%;
    .versionDemo{
      width: 380px;
      height: 600px;
      border-radius: 10px;
      border: 1px solid #E5E5E5;
      .demotop{
        height: 160px;
        border-radius: 10px 10px 0px 0px;
        position: relative;
        .demotop-content{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          z-index: 500;
          .toph1{
            font-size: 32px;
            line-height: 1.5;
            color: white;
            font-weight: bold;
            z-index: 500;
          }
          .toph2{
            font-size: 16px;
            color: white;
            line-height: 1.5;
            text-align: center;
            width: 70%;
            z-index: 300;
          }
        }
        .imgbgc{
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }
      }
      .democontent{
        padding: 20px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        .choosebtn{
          width:120px;height:40px;margin:25px auto;
          font-size: 14px;
        }
        .price{
          color: black;
          text-align: center;
          line-height: 1.5;
        }
        .democontains{
          /* border:1px solid black; */
          width:80%;
          margin:0px auto;
          .containshead{
            font-size: 16px;
            color: black;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 20px;
          }
          .containsfunction{
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #666666;
            margin:10px 0px;
            .iconbox{
              width: 16px;
              height: 16px;
              border:1px solid #666666;
              border-radius: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              color: #666666;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .chooseService{
      width: 600px;
      /* height: 610px; */
      background: #FFFFFF;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      border: 1px solid #E5E5E5;
      padding: 20px;
      .chooseService-title{
        font-size: 16px;
        /* font-weight: bold; */
        line-height: 1.5;
        color: #303133;
      }
      .chooseService-cycle{
        margin-bottom: 20px;
        display: flex;
        flex-direction: column-reverse;
        .cycle-box{
          border: 1px solid #E5E5E5;
          /* padding: 15px; */
          height: 70px;
          padding: 0px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          cursor: pointer;

        }
        .bluemask{
          background: rgba(64, 158, 255,.15);
          position: relative;
          width: 100%;
          height: 100%;
          height: 70px;
        }
        .cycle-price{
          .topprice{
            color: #303133;
            font-size: 16px;
            font-weight: bold;
          }
          .bottomprice{
            color:#f56c6c;font-size:12px;
            margin-top:5px
          }
        }
      }
      .chooseService-giftbag{
        .giftbag-box{
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .giftbag-font{
            font-size: 14px;
            color: #606266;
            line-height: 1.5;
            margin-left: 10px;
          }
        }
      }
    }
    .pay{
      .pay-graybox{
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        padding: 23px 35px;
        width: 600px;
        .pay-tips{
          font-size: 16px;
          color: #333333;
          line-height: 1.5;
        }
        .pay-secfont{
          color: #666666;
          font-size: 14px;
          line-height: 1.5;
        }
        .pay-suctips{
          font-size: 14px;
          color: #333333;
          line-height: 1.5;
          flex-shrink: 0;
        }
        .pay-sucgraytips{
          font-size: 14px;
          color: #606266;
          line-height: 1.5;
          flex:1;
          text-align:right
        }
      }
    }
  }
}
.contact{
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 999;
  /* border: 1px solid black; */
  .contact-font1{
    font-size: 14px;
    color: #333333;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
  }
  .contact-font2{
    font-size: 14px;
    color: #999999;
    line-height: 1.5;
    text-align: center;
  }
}
</style>